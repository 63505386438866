document.addEventListener('DOMContentLoaded', function () {

    // audio

    window.audioScan = document.createElement('audio');
    window.audioScan.setAttribute("src", "/audio/scan.wav");
    window.audioSuccess = document.createElement('audio');
    window.audioSuccess.setAttribute("src", "/audio/success.mp3");
    window.audioError = document.createElement('audio');
    window.audioError.setAttribute("src", "/audio/error.wav");


    // window on scroll

    window.onscroll = function() {
        var scrollBtns = document.getElementById("scrollBtns");
        if (window.pageYOffset > 100) {
            scrollBtns.style.right = 15+"px";
        }else{
            scrollBtns.style.right = "-"+200+"px";
        }
    };
    // scroll to top on click
    $("#scrollToTop").on('click',function(event) {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    $("#scrollToBottom").on('click',function(event) {
        event.preventDefault();
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    });

    // go back button

    var goToPreviousPageBtn = document.getElementById("goToPreviousPage");
    if(goToPreviousPageBtn)
    {
        goToPreviousPageBtn.addEventListener("click", function (event) {
            event.preventDefault();
            var message = goToPreviousPageBtn.getAttribute("data-confirm-message");
            var confirmation = confirm(message);
            if (!confirmation) {
                event.stopPropagation();
            }else{
                window.allow_unload = true;
                //history.back();
                var back_url = goToPreviousPageBtn.getAttribute("data-previous-page");
                window.location.href = back_url;
            }
        });
    }


    // submit form button

    var submitFormBtn = document.getElementById("saveFormById");
    // get globalOverlay
    var spinner = '<div class="spinner-grow text-dark position-fixed top-50 start-50 translate-middle" role="status"><span class="visually-hidden">Loading...</span></div>';
    var overlay = '<div class="bodyOverlay">'+spinner+'</div>';
    if(submitFormBtn)
    {
        submitFormBtn.addEventListener("click", function (event) {
            event.preventDefault();
            var message = submitFormBtn.getAttribute("data-confirm-message");
            var confirmation = confirm(message);
            if (!confirmation) {
                event.stopPropagation();
            }else{
                if (submitFormBtn.hasAttribute('data-form-id')) {
                    var formId = submitFormBtn.getAttribute('data-form-id');
                    if(formId.length > 0){
                        var form = document.getElementById(formId);
                        var canSubmit = true;
                        var traderS1IdInput = document.getElementById('traderS1Id');

                        if(traderS1IdInput !== undefined && traderS1IdInput !== null)
                        {
                            if(traderS1IdInput.value.length == 0)
                            {
                                canSubmit = false;
                                var invalidFeedbackTrader = document.getElementById('invalid-feedback-trader');
                                    invalidFeedbackTrader.scrollIntoView();
                            }

                        }

                        var generatedProducts = document.getElementById('generatedProducts');
                        if(generatedProducts !== undefined && generatedProducts !== null && canSubmit)
                        {
                            if($('#generatedProducts tbody tr').length == 2)
                            {
                                canSubmit = false;
                                var tableProductsAlert = document.getElementById('table-products-alert');
                                tableProductsAlert.scrollIntoView();
                            }
                        }

                        if (form.checkValidity() && canSubmit) {
                            $('body').append(overlay);
                            window.allow_unload = true;
                            form.submit();
                            setTimeout(() => {
                                $('div.bodyOverlay').remove();
                            }, 10000);
                        }else{
                            var errorElements = document.querySelectorAll(".form-control:invalid");
                            if(errorElements[0] !== undefined)
                                errorElements[0].scrollIntoView();
                        }

                        form.classList.add('was-validated')
                    }
                }
            }

        });
    }


    var sendToS1ByIdForm = document.getElementById("sendToS1ById");
    if(sendToS1ByIdForm)
    {
        sendToS1ByIdForm.addEventListener("submit", function (event) {
            event.preventDefault();
            var message = sendToS1ByIdForm.getAttribute("data-confirm-message");
            var confirmation = confirm(message);
            if (!confirmation) {
                event.stopPropagation();
            }else{
                $('body').append(overlay);
                sendToS1ByIdForm.submit();
                setTimeout(() => {
                    $('div.bodyOverlay').remove();
                }, 10000);
            }
        });
    }

    var destroyByIdForm = document.getElementById("destroyById");
    if(destroyByIdForm)
    {
        destroyByIdForm.addEventListener("submit", function (event) {
            event.preventDefault();
            var message = destroyByIdForm.getAttribute("data-confirm-message");
            var confirmation = confirm(message);
            if (!confirmation) {
                event.stopPropagation();
            }else{
                $('body').append(overlay);
                destroyByIdForm.submit();
                setTimeout(() => {
                    $('div.bodyOverlay').remove();
                }, 10000);
            }
        });
    }

    document.body.addEventListener("click", function (event) {
        // create picking btn
        if (event.target.classList.contains("createPicking")) {
            $('body').append(overlay);
            setTimeout(() => {
                $('div.bodyOverlay').remove();
            }, 10000);
        }
    });

    if(document.querySelector('.sendToS1'))
    {
        document.querySelector('.sendToS1').addEventListener('submit', (e) => {
            e.preventDefault();
            message = e.target.getAttribute("data-confirm-message");
            var confirmation = confirm(message);
            if (!confirmation) {
                e.stopPropagation();
            }else{
                $('body').append(overlay);
                e.currentTarget.submit();
                setTimeout(() => {
                    $('div.bodyOverlay').remove();
                }, 10000);
            }
        });
    }

    var orderForm = document.getElementById("orderForm");
    if(orderForm)
    {
        window.addEventListener('beforeunload', function(e) {
            if (window.allow_unload) {
                console.log('allow_unload');
                return;
            }
            e.preventDefault(); //per the standard
            e.returnValue = '';
            var leavingConfirmation = document.getElementById("leavingConfirmation");
            var leavingConfirmationMessage = leavingConfirmation.getAttribute("data-confirm-message")
            var confirmation = confirm(leavingConfirmationMessage);
            if (!confirmation) {
                e.preventDefault(); //per the standard
            }
        });
    }



});
